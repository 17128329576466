import {
  Customer as MedusaCustomer,
  GiftCard,
  LineItem,
  Order,
  Product as MedusaProduct,
  ProductCollection,
  ProductVariant as MedusaProductVariant,
  User as MedusaUser,
} from "@medusajs/medusa";
import { PaginationInput } from "@hooks/pagination";
import { SeatStatus } from "@utils/seating-map/seat";
import { CartCustomerValues } from "@app/[locale]/b/[domain]/store/cart/page";
export const PRODUCT_TYPE_DIGITAL = "product_type_digital_product";
export const PRODUCT_TYPE_EVENT = "product_type_event";
export interface VenueArrangement {
  id: string;
  name: string;
  venue: Venue;
  capacity: number;
}

export interface Venue {
  id: string;
  name: string;
  description: string;
  address: string;
  city: City;
  province: string;
  postalCode: string;
  country: string;
  phone: string;
  slug: string;
  timezone: string;
  arrangements: VenueArrangement[];
  instagramProfile: string;
  twitterProfile: string;
  tiktokProfile: string;
  thumbnail: string;
  notificationSubscriptions: VenueNotificationSubscription[];
  ticketStrategy: string;
  analyticsCode: string;
  lat: number;
  lon: number;
  eventDisplayMode: EventDisplayMode;
  providerPlaceId?: string;
  is_virtual: boolean;
  is_show_follow_option_enabled: boolean;
  maps_url: string;
}

export type OrderNote = {
  note: string;
};

export interface Currency {
  code: string;
}

export interface Ticket {
  id: string;
  productVariant: ProductVariant;
  order: Order;
  status: string;
  scannedDeviceId: string;
  seatAllocation: SeatAllocation;
  groupInfo: string;
  line_item_id: string;
  assigned_to: string;
}

export type PaginationContext = PaginationInput & {
  count: number;
};

export type PaginatedList<T> = PaginationContext & {
  items: T[];
};

export type AuthError = {
  code?: string;
  message: string;
  type: string;
};

export type User = MedusaUser & {
  tips: Tip[];
  accounts: Account[];
  paymentDestination: PaymentDestination;
  participant: Participant;
};

export type Product = MedusaProduct & {
  account: Account;
  venueArrangement: VenueArrangement;
  venue: PlaceAddress;
  activeVariants: ProductVariant[];
  inactiveVariants: ProductVariant[];
  categories: ProductCategory[];
  gigSheetTemplates: Template[];
  paymentStrategy: string;
  orderCount: number;
  campaigns: Campaign[];
  eligibleForContestGiveaway: boolean;
  availableAt: Date;
  externalOrderUrl: string;
  giftCardProductCategoryRestrictions: ProductCategoryRestriction[];
  passStartsAt: Date;
  passEndsAt: Date;
  isPass: boolean;
  isTaxable: boolean;
  createdBy: User;
  lowInventoryMessage: string;
  favorites: FavoriteProduct[];
  featured_reviews: FeaturedProductReview[];
  pass_usage_limit: number;
  rank: number;
  brand: Brand;
};

export type Widget = {
  id: number;
  name: string;
  type: string;
  config: Record<string, any>;
  draft_config: Record<string, any>;
  inserted_at: Date;
};

export type VariantParticipant = {
  id: string;
  variant: ProductVariant;
  participant: Participant;
  role: string;
};
export type ProductVariant = MedusaProductVariant & {
  variantParticipants: VariantParticipant[];
  seatingChart: SeatingChart;
  seatPrices: SeatPrice[];
  startsAt: string;
  stopSellingAt: Date;
  lowInventoryWarningThreshold: number;
  serviceFeeStrategy: ServiceFeeStrategy;
  taxStrategy: TaxStrategy;
  customerPaysPrice: number;
  reminderNotificationStrategy: ReminderNotificationStrategy;
  reminderMessage: string;
  pay_what_you_like_label: string;
  is_pay_what_you_like: boolean;
  virtual_event_timezone: string;
  display_showtime: boolean;
};

export type Participant = {
  id: string;
  name: string;
  picture: string;
  instagramProfile: string;
  twitterProfile: string;
  tiktokProfile: string;
  profile: string;
  slug: string;
  websiteUrl: string;
  contentBlocks: ParticipantContentBlock[];
};

export type ParticipantUser = Participant & {
  user: User;
};

export const INITIATED_AUTH_FROM_PATH = "initiatedAuthFromPath";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function isPaymentAccount(obj: any): obj is PaymentAccount {
  return (
    obj !== undefined && (obj as PaymentAccount).default_currency !== undefined
  );
}
export interface PaymentAccount {
  id: string;
  charges_enabled: boolean;
  default_currency: string;
  details_submitted: boolean;
}

export interface AccountLink {
  url: string;
  created: number;
  expires_at: number;
}

export type ProductPaymentInfo = {
  transferAmount: number;
  salesAmount: number;
};

export function isProductPaymentInfo(obj: any): obj is ProductPaymentInfo {
  return (
    obj !== undefined &&
    (obj as ProductPaymentInfo).salesAmount !== undefined &&
    (obj as ProductPaymentInfo).transferAmount !== undefined
  );
}

export type Tip = {
  amount: number;
  currency: string;
  name: string;
  note: string;
  unitCount: number;
  unitCost: number;
};

export type Province = {
  name: string;
  abbreviation: string;
};

export type Country = {
  name: string;
  abbreviation: string;
};

export type Follow = {
  venue: Venue;
};

export type Customer = MedusaCustomer & {
  venueFollows: Follow[];
};

export type NotificationType = {
  id: string;
  description: string;
};

export type VenueNotificationSubscription = {
  id: string;
  notificationType: NotificationType;
  venue: Venue;
};

export type PaymentDestination = {
  id: string;
  name: string;
  identifier: string;
  currency: string;
  paymentAccountId: string;
  paymentAccountMetadata: Record<string, any>;
  paymentAccount: PaymentAccount;
  enabled: boolean;
  created_at: Date;
  ref_code: string;
  description_info: {
    id: string;
    name: string;
    entity_type: string;
    email: string;
    payment_method: string;
  };
};

export type Account = {
  id: string;
  name: string;
  storeUrl: string;
  cmsApiKey: string;
  cmsSecretKey: string;
  productCategories: ProductCategory[];
  users: User[];
  paymentDestinations: PaymentDestination[];
  analyticsCode: string;
  marketingStrategy: string;
  logoImage: string;
  replyToEmail: string;
  fromEmailName: string;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  brandColor1: string;
  brandColor2: string;
  paymentStrategy: string;
  country: string;
  province: string;
  orderCompleteMessage: string;
  collection: ProductCollection;
  paymentCurrency: string;
  sendSaleNotificationsStrategy: SendSaleNotificationsStrategy;
  reviewEmailSubject: string;
  reviewEmailEditorContent: string;
  reviewEmailBody: string;
  reviewEmailStrategy: ReviewEmailStrategy;
  chargeTaxesByDefault: boolean;
  reminderNotificationStrategy: ReminderNotificationStrategy;
  alternateOrderNotificationEmail: string;
  additionalCheckoutFields: AdditionalCheckoutField[];
  analytics_elements: AnalyticsElement[];
  platform_id: number;
  is_marked_for_deletion: boolean;
  platform: Platform;
  payment_destination: PaymentDestination;
};

export type Platform = {
  base_url: string;
};

export enum ReviewEmailStrategy {
  SEND_DEFAULT = "send-default",
  SEND_CUSTOM = "send-custom",
  DO_NOT_SEND = "do-not-send",
}

export enum FormModes {
  CREATE,
  UPDATE,
}

export type ProductCategory = {
  id: string;
  name: string;
  handle: string;
  description: string;
  created_at: Date;
};

export type TicketGroup = {
  id: string;
  tickets: Ticket[];
};

export type City = {
  id: string;
  name: string;
  slug: string;
  thumbnail: string;
};

export type EmailContent = {
  subject: string;
  body: string;
};

export type DraftOrderInvite = {
  id: string;
  name: string;
  code: string;
  draftOrderInviteVariants: DraftOrderInviteVariant[];
  maxTicketsPerShowPerEmail: number;
  maxTickets: number;
  defaultTicketsPerShowPerEmail: number;
  totalRedemptions: number;
  created_at: Date;
  user: User;
};

type DraftOrderInviteVariant = {
  variant: ProductVariant;
};

export type Review = {
  id: string;
  variant: ProductVariant;
  rating: number;
  note: string;
  customer: Customer;
  participant: Participant;
  created_at: Date;
  featured_on_products: FeaturedProductReview[];
};

export type MailingList = {
  id: string;
  account: Account;
  created_at: Date;
  subscriberCount: number;
  slug: string;
  welcome_email: Email;
  is_new_subscriber_email_notification_enabled: boolean;
};

export type Campaign = {
  name: string;
  id: string;
  account: Account;
  created_at: Date;
  updated_at: Date;
  sentAt: Date;
  template?: Template;
  status: string;
  body: string;
  subject: string;
  editorContent: string;
  fromEmailName: string;
  replyToEmail: string;
  mailingList: MailingList;
  subscriberFilters: SubscriberFilter[];
  product?: Product;
  scheduledAt?: Date;
  schedule: CampaignSchedule;
  clicks: number;
  opens: number;
  bounced: number;
  filterStrategy: FilterStrategy;
  sendStrategy: SendStrategy;
  sendToSubscriberStartPosition: number;
  sendToSubscriberEndPosition: number;
  sentToCount: number; // stored in DB after campaign is sent
  targetSubscriberCount: number; // temporary field when creating campaign
  unsubscribeCount: number;
  ordersGenerated: number;
  thumbnail: string;
  excludedAudiences: ProductVariant[];
  excludedOpenedCampaigns: Campaign[];
  included_labels: Label[];
  excluded_labels: Label[];
  included_variants: ProductVariant[];
};

export type Subscription = {
  id: string;
  created_at: Date;
  unsubscribed_at: Date;
  subscriber: Subscriber;
  activities: SubscriptionActivity[];
  labels: Label[];
};

export type Subscriber = {
  id: string;
  name: string;
  email: string;
  status: string;
  city: string;
  province: string;
  country: string;
  subscriptions: Subscription[];
};

export type ApiError = {
  error: string[];
};

export type PlaceAddress = {
  id: string;
  name: string;
};

export type Feedback = {
  text: string;
};

export type ContentBlock = {
  id: string;
  title: string;
  text: string;
  thumbnail: string;
  url: string;
  created_at: Date;
  displayFormat: string;
  enabled: boolean;
  type: string;
};

export type ProductContentBlock = {
  id: number;
  product: Product;
  content_block: ContentBlock;
  type: string;
};

export type LocationResult = {
  status: string;
  city: string;
  lat: number;
  lon: number;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  timezone: string;
};

export type ProductSaleNotificationSubscription = {
  id: string;
  email: string;
  created_at: Date;
};

export type Game = {
  id: number;
  name: string;
  identifier: string;
};

export type Contest = {
  id: number;
  name: string;
  account: Account;
  game: Game;
  description: string;
  subscribed_lost_text: string;
  not_subscribed_lost_text: string;
  win_probability: number;
  thumbnail: string;
  marketing_opt_in_prompt: string;
  terms: string;
  entry_rule: string;
  game_config: Record<string, any>;
  lost_image: string;
  rules: string;
  prizes: Prize[];
  key: string;
  inserted_at: Date;
  discount_codes: ContestDiscountCode[];
  discount_codes_email: Email;
};

export type ContestDiscountCode = {
  id: number;
  name: string;
  quantity: number;
  reason: string;
  description: string;
  discount_type: "percentage" | "fixed";
  discount_value: number;
  is_enabled: boolean;
  currency_code: string;
  discount_allocation: "item" | "total";
  discount_code_prefix: string;
  eligibility_rule: string;
  prize: Prize;
  inserted_at: Date;
};

export type ContestEntry = {
  id: string;
  name: string;
  email: string;
  subscribed: boolean;
  prize: Prize;
  inserted_at: Date;
  contest: Contest;
};

export type Template = {
  id: string;
  thumbnail: string;
  name: string;
  text: string;
  account: Account;
  allTemplateVariables: TemplateVariable[];
  created_at: Date;
  editorContent: string;
  editor_content: string;
};

export type TemplateVariable = {
  id: string;
  name: string;
  type: string;
  tagName: string;
};

export type CustomImage = {
  url: string;
};

export type SubscriberFilter = {
  name: string;
  label: string;
  options: SubscriberFilterOption[];
  includeEmptyField: boolean;
  hasEmptyField: boolean;
  exclusion: boolean;
};

export enum SendStrategy {
  SEND_NOW = "send-now",
  SEND_LATER = "send-later",
}

export type CampaignSchedule = {
  dates?: string[]; // deprecated
  payloads: CampaignSchedulePayload[];
};

export type CampaignSchedulePayload = {
  date: Date;
  overrides: {
    subject: string;
  };
};

export type SubscriberFilterOption = {
  name: string;
  value: string;
  selected: boolean;
};

export type SeatingChart = {
  id: string;
  name: string;
  content: any;
  created_at: Date;
  updated_at: Date;
};

export type SeatAllocation = {
  seatNumber: string;
  seatId: string;
  selectedInCart: boolean;
  status: SeatStatus;
};

export type SeatPrice = {
  currency_code: string;
  amount: number;
  seat_identifier: string;
};

export type ProductCategoryRestriction = {
  productCategory: ProductCategory;
};

export type PosLocation = {
  account: Account;
  paymentProcessorLocationId: string;
  readers: CardReader[];
};

export type CardReader = {
  id: string;
  label: string;
};

export type PosStatus = {
  cardReaderId: string;
  paymentStatus: string;
  cardReaderStatus: string;
  cardReaderLabel: string;
};

export enum FilterStrategy {
  SEND_TO_ALL = "send-to-all",
  APPLY_FILTERS = "apply-filters",
  APPLY_INDEX = "apply-index",
}

export type AccountPrivilegesBare = {
  accountId: string;
  privileges: string[];
};

export type Permission = {
  id: string;
  name: string;
  description: string;
  identifier: string;
};

export type AccountPrivilege = {
  id: string;
  user: User;
  account: Account;
  permission: Permission;
  updated_at: Date;
};

export type ReferralAmount = {
  currency: string;
  amount: number;
};

export type ReferralTransaction = {
  id: string;
  lineItem: LineItem;
  payableAmount: number;
  currency: string;
};
export type Money = {
  amount: number;
  currency: string;
};

export type ReferredAccountTotal = {
  account: Account;
  amounts: Money[];
};

export enum PaymentTransactionStatus {
  settled = "settled",
  unsettled = "unsettled",
}

export type ReferralFeeAmounts = {
  type: PaymentTransactionStatus;
  amounts: Money[];
};

export enum SendSaleNotificationsStrategy {
  DO_NOT_SEND = "do-not-send",
  SEND = "send",
}

export type BatchJobLog = {
  created_at: Date;
  text: string;
};

export type Question = {
  id: string;
  text: string;
};

export type Survey = {
  id: string;
  title: string;
  introText: string;
  questions: Question[];
};

export interface CreateAccountValues {
  currencyCode: string;
  countryCode: string;
  accountId?: string;
}

export enum EventListFilterTypes {
  SHOW_PAST = "show-past",
  SHOW_UPCOMING = "show-upcoming",
  SHOW_ALL = "show-all",
  SHOW_FAVORITES = "show-favorites",
  CALENDAR = "calendar",
}

export enum TicketStatus {
  CREATED = "created",
  REFUNDED = "refunded",
  CHECKED = "checked",
}

export enum MarketingStrategy {
  OPT_IN = "opt-in",
  ADD_AUTOMATICALLY = "add-automatically",
}

export type UnsubscribeStats = {
  total: number;
  inLastWeek: number;
  inLastMonth: number;
};

export enum CampaignStatus {
  SENT = "sent",
  DRAFT = "draft",
  SCHEDULED = "scheduled",
}

export interface Respondent {
  email: string;
}
export interface Answer {
  respondent: Respondent;
  question: Question;
  text: string;
}
export interface SurveyResult {
  respondent: Respondent;
  respondedAt: Date;
  responses: {
    question: Question;
    answer: Answer;
  }[];
}

export type CalendarEvent = {
  title: string;
  start: Date;
  end: Date;
  productId: string;
  startTime: string;
  handle: string;
  timezone: string;
  thumbnail: string;
  startsAt: string;
};

export type ParticipantContentBlock = {
  id: string;
  participant: Participant;
  contentBlock: ContentBlock;
  sortOrder: string;
};

export enum ContentBlockDisplayFormat {
  NO_IMAGE = "no-image",
  SMALL = "small",
  BANNER = "banner",
  HIGHLIGHT = "highlight",
}

export type Favorite = {
  id: string;
  user: User;
};

export type FavoriteProduct = Favorite & {
  product: Product;
};

export enum ServiceFeeStrategy {
  ABSORB = "absorb",
  PASS_TO_CUSTOMER = "pass-to-customer",
}

export enum TaxStrategy {
  ABSORB = "absorb",
  PASS_TO_CUSTOMER = "pass-to-customer",
}

export type ItemServiceFeeInfo = {
  itemId: string;
  amount: number;
  taxRate: number;
};

export enum ReminderNotificationStrategy {
  DO_NOT_SEND = "do-not-send",
  SEND_DEFAULT = "send-default",
}

export type SubscriptionActivity = {
  id: string;
  variant: ProductVariant;
  product: Product;
  participant: Participant;
  venue: Venue;
};

export enum EventDisplayMode {
  CALENDAR = "calendar",
  LIST = "list",
}

export type DeferredPaymentAmount = {
  type: PaymentTransactionStatus;
  amounts: Money[];
};

export type VariantPaymentAmounts = {
  variant: ProductVariant;
  amounts: DeferredPaymentAmount[];
  taxAmounts: DeferredPaymentAmount[];
};

export type AdPlatform = {
  id: number;
  name: string;
  identifier: string;
};

export type AdPlatformAccount = {
  id: string;
  access_token: string;
  ad_platform: AdPlatform;
  external_ad_account_identifier: string;
};

export type ReferenceData = {
  id: string;
  key: string;
  title: string;
  description: string;
};

export type AdPlatformCampaign = {
  id: string;
  name: string;
  inserted_at: Date;
  ad_platform_account: AdPlatformAccount;
};

export enum AudienceSegmentType {
  PARTICIPANT = "participant",
  VENUE = "venue",
  PRODUCT = "product",
  LOCATION = "location",
  CONTEST = "contest",
}
export type AudienceSegment = {
  id: string;
  type: AudienceSegmentType;
};

export type ReviewAnalyzerResults = {
  average_rating: number;
  total_ratings: number;
  five_star: number;
  four_star: number;
  three_star: number;
  two_star: number;
  one_star: number;
};

export type AnalyticsElementType = {
  id: number;
  name: string;
  identifier: string;
  name_label: string;
  key_label: string;
  secret_label: string;
};
export type AnalyticsElement = {
  id: number;
  name: string;
  description: string;
  analytics_element_type: AnalyticsElementType;
  key: string;
  secret: string;
};

export type AdditionalCheckoutField = {
  id: string;
  name: string;
  type: string;
  validation_schema: {
    rules: string[];
    options: { value: string; label: string }[];
  };
  account?: Account;
  product?: Product;
  is_enabled: boolean;
  display_order: number;
  select_options: string[];
};

export type FeaturedProductReview = {
  id: number;
  product: Product;
  review: Review;
  inserted_at: Date;
};

export type FeaturedProduct = {
  id: number;
  product: Product;
  featured_product: Product;
};

export type Page = {
  id: number;
  name: string;
  slug: string;
  editor_content: string;
  brand: Brand;
  url: string;
  is_enabled: boolean;
  show_in_menu: boolean;
  is_external: boolean;
  parent_page: Page;
  display_order: number;
};
export type Brand = {
  id: number;
  name: string;
  slug: string;
  thumbnail: string;
  share_thumbnail: string;
  pages: Page[];
  domain: string;
  default_menu: Menu;
  more_from_creator_label: string;
  theme_settings: Record<string, any>;
  sender_name: string;
  reply_to_email: string;
  show_find_tickets_menu_item: boolean;
  is_map_shown_on_event_page: boolean;
  is_show_more_from_creator_enabled: boolean;
  in_stock_label: string;
  default_add_to_cart_quantity: number;
  max_add_to_cart_quantity: number;
  is_terms_of_service_enabled: boolean;
  logo_link_url: string;
};

export type Menu = {
  id: string;
  name: string;
  brand_id: string;
  menu_items: MenuItem[];
};

export type Label = {
  id: number;
  name: string;
};

export type PassesInfo = {
  events: Product[];
  passes: GiftCard[];
};

// Taken from: https://github.com/vercel/platforms/blob/29e20e790eaf17d4d5051c23a69636dce724c174/lib/types.ts
export interface DomainConfig {
  /** How we see the domain's configuration. - `CNAME`: Domain has a CNAME pointing to Vercel. - `A`: Domain's A record is resolving to Vercel. - `http`: Domain is resolving to Vercel but may be behind a Proxy. - `null`: Domain is not resolving to Vercel. */
  configuredBy?: ("CNAME" | "A" | "http") | null;
  /** Which challenge types the domain can use for issuing certs. */
  acceptedChallenges?: ("dns-01" | "http-01")[];
  /** Whether or not the domain is configured AND we can automatically generate a TLS certificate. */
  misconfigured: boolean;
}

export type MenuItem = {
  id: string;
  label: string;
  url: string;
  target: string;
  menu_id: string;
  page_id: string | null;
  page: Page;
  resolved_url: string;
};

export type Prize = {
  id: number;
  name: string;
  winner_message: string;
  max_winners: number;
  win_probability: number;
  thumbnail: string;
  period_duration_in_seconds: number;
  max_winners_per_period: number;
  inserted_at: Date;
};

export type ReferralLink = {
  id: number;
  code: string;
  name: string;
  inserted_at: Date;
  orders: Order[];
};

export type PaymentFormProps = {
  total: string | undefined;
  customerValues: CartCustomerValues;
  onSuccessCallback?: (cartId: string) => void;
};

export type PurchasedGiftCard = {
  id: string;
  code: string;
  order_display_id: number;
  order_created_at: Date;
  customer_name: string;
  product_title: string;
  product_thumbnail: string;
  email: string;
  email_subject: string;
  email_content: string;
};

export type Affiliate = {
  id: number;
  name: string;
};
export type AffiliateCode = {
  id: number;
  code: string;
  affiliate: Affiliate;
};

export type Email = {
  id: number;
  content: string;
  body: string;
  subject: string;
  from_name: string;
  reply_to_email: string;
  is_enabled: boolean;
};

export type MailingListSummary = {
  id: number;
  last_sent: Date;
  next_scheduled: Date;
  send_time: Date;
  inserted_at: Date;
  to_email: string;
  summary_type: SummaryType;
};

export type SummaryType = {
  id: number;
  name: string;
};

export type ProductAvailableForSaleSubscription = {
  id: string;
  product: Product;
  email: string;
  created_at: Date;
};

export type ProductAvailableForSaleSubscriptionSummaryRow = {
  product_id: string;
  product_title: string;
  subscription_count: number;
  location: string;
  showtimes: string;
};

export type TicketSummary = {
  variant_id: string;
  starts_at: string;
  title: string;
  total_tickets_sold: number;
  total_tickets_refunded: number;
  net_tickets_sold: number;
  tickets_checked_in: number;
  comped_tickets: number;
};
